import { Box, Image, HStack} from "@chakra-ui/react";
import { useContext, useState } from "react";
import placeholder1 from '../../assets/img/placeholder1.png';
import placeholder2 from '../../assets/img/placeholder2.png';
import placeholder3 from '../../assets/img/placeholder3.png';
import beachThumbnail from '../../assets/img/beach-thumbnail.png';
import duckFile from '../../assets/json/duck.json';
import seagullFile from '../../assets/json/seagull.json';
import cactusFile from '../../assets/json/cactus.json';
import beachFile from '../../assets/json/beach.json';
import EditorContext from "../../context/EditorContext";
import LoadExampleConfirmModal from "../modals/LoadExampleConfirmModal";

function ExampleTile({ title, img, file, onLoadComplete}) {
    const [loadOpen, setLoadOpen] = useState(false);
    const {setFile} = useContext(EditorContext);

    return (
        <Box
            borderRadius="10px"
            border="1px solid rgba(0,0,0,0.1)"
            w="250px"
            h="250px"
            overflow="hidden"
            mr="10px"
            mb="10px"
            display="flex"
            flexDir="column"
            alignItems="stretch"
            justifyContent="stretch"
            cursor="pointer"
            onClick={() => setLoadOpen(true)}
        >
            <Image flex="0 0 auto" w={'250px'} h={'200px'} objectFit={'cover'} src={img}></Image>
            <Box
                flex="1"
                display="flex"
                flexDir="row"
                alignItems="center"
                justifyContent="flex-start"
                paddingLeft="10px"
                paddingRight="10px"
                fontWeight="bold"
            >{title}</Box>
            <LoadExampleConfirmModal isOpen={loadOpen} onConfirm={() => {
                setLoadOpen(false);
                onLoadComplete();
                setFile(file);
            }}
            onCancel={() => setLoadOpen(false)} />
        </Box>
    )
}

function ExampleLibrary({ onClose }) {
    return (<Box
        flex='1'
        minHeight='500px'
        overflow='auto'
        borderTop='1px solid rgba(0,0,0,0.1)'
        padding="10px"
        display="flex"
        flexDir="row"
        flexWrap="wrap"
    >
        <ExampleTile title={'Duck'} img={placeholder1} file={duckFile} onLoadComplete={onClose} />
        <ExampleTile title={'Seagull'} img={placeholder2} file={seagullFile} onLoadComplete={onClose} />
        <ExampleTile title={'Cactus'} img={placeholder3} file={cactusFile} onLoadComplete={onClose} />
        <ExampleTile title={'Beach'} img={beachThumbnail} file={beachFile} onLoadComplete={onClose} />
    </Box>)
}

export default ExampleLibrary;