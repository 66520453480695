import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'
import { 
  CheckIcon, CloseIcon} from '@chakra-ui/icons';

function NewProjectModal({ isOpen, onConfirm, onCancel }) {
    return (
        <Modal isOpen={isOpen} onClose={() => onCancel()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Start New Project?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            This will clear your workspace and start fresh. Are you sure?
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button leftIcon={(<CheckIcon />)} variant='outline' colorScheme='red' mr={3} onClick={() => onConfirm()}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
}

export default NewProjectModal;