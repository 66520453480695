import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box
} from '@chakra-ui/react'
import { 
  CheckIcon, CloseIcon} from '@chakra-ui/icons';
import ExampleLibrary from '../features/ExampleLibrary';

function LoadExampleModal({ isOpen, onClose }) {
    return (
        <Modal size="4xl" isOpen={isOpen} onClose={() => onClose()}>
        <ModalOverlay />
        <ModalContent margin="20px">
          <ModalHeader>BlockRocket Examples</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding="0px">
            <ExampleLibrary onClose={() => onClose()} />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
}

export default LoadExampleModal;