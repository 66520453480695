import { Box } from "@chakra-ui/react";
import { useContext, useState } from "react";
import EditorContext from "../context/EditorContext";
import DebugFooter from "./features/DebugFooter";
import Navbar from "./features/PrimaryNavbar";
import SecondaryNavbar from "./features/SecondaryNavbar";
import VoxelEditorView from "./features/VoxelEditorView";
import VoxelRendererView from "./features/VoxelRendererView";

function App() {
  const { file, tab, setTab } = useContext(EditorContext);

  return (
    <Box
      flex="1"
      display="flex"
      flexDir="column"
      justifyContent="stretch"
      alignItems="stretch"
      overflow="scroll"
      bgColor="gray.100"
    >
      <Navbar />
      <SecondaryNavbar />
      {tab === 0 && <VoxelEditorView />}
      {tab === 1 && <VoxelRendererView />}
      <DebugFooter />
    </Box>
  );
}

export default App;
