import { Box, Button, Tabs, TabList, Tab, Text } from "@chakra-ui/react";
import { useContext } from "react";
import {
  SECONDARY_NAV_HEIGHT,
  SECONDARY_NAV_HEIGHT_PX,
} from "../../constants/layoutConstants";
import EditorContext from "../../context/EditorContext";

function SecondaryNavbar() {
  const { tab, setTab } = useContext(EditorContext);

  return (
    <Box
      flex="0 0 auto"
      width="100%"
      backgroundColor="white"
      overflow="hidden"
      display="flex"
      alignItems="stretch"
      shadow="lg"
      height={SECONDARY_NAV_HEIGHT}
      zIndex="3"
    >
      <Tabs flex="1" align="center" index={tab} onChange={setTab}>
        <TabList>
          <Tab>Edit</Tab>
          <Tab>Render</Tab>
        </TabList>
      </Tabs>
    </Box>
  );
}

export default SecondaryNavbar;
