import { Box, Button, VStack } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useContext, useState } from "react";
import EditorContext from "../../context/EditorContext";



function Sidebar({ children }) {
    const { sidebarOpen, setSidebarOpen } = useContext(EditorContext);

    return (
        <Box
            flex="0 0 auto"
            height="100%"
            width="300px"
            position="absolute"
            top="0px"
            bottom="0px"
            right={sidebarOpen ? '0px' : '-260px'}
            transition="right 0.25s"
            display="flex"
            zIndex="2"
            pointerEvents="none"
        >
            <Box
                w="40px"
                pointerEvents="none"
            >
                <Button 
                    variant="ghost" 
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    w="40px"
                    h="150px"
                    bg="white"
                    border="1px solid rgba(0,0,0,0.1)"
                    borderTopRightRadius="0px"
                    borderBottomRightRadius="0px"
                    borderTopLeftRadius="0px"
                    borderTop="none"
                    borderRight="1px solid white"
                    position="relative"
                    pointerEvents="auto"
                    zIndex="1"
                    transform="translateX(1px)"
                    display="flex"
                    flexDir="column"
                    justifyContent="stretch"
                    alignItems="center"
                    paddingBottom="15px"
                    paddingTop="15px"
                >              
                    <Box flex="0 0 auto" mb="10px">
                        <Box transform="rotateZ(-90deg) translateX(-40px)">Tool Palette</Box>
                    </Box>
                    <Box flex="1"></Box>
                    {sidebarOpen ? (
                        <ChevronRightIcon />
                    ) : (
                        <ChevronLeftIcon />
                    )}
                </Button>
            </Box>
            <Box
                flex="1"
                minW="200px"
                bg="white"
                borderLeft="1px solid rgba(0,0,0,0.1)"
                shadow="lg"
                padding="10px"
                overflow="auto"
                pointerEvents="auto"
                zIndex="0"
            >
                {children}
            </Box>
        </Box>
    )
}

export default Sidebar;