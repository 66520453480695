import { Box, Button, Text, Image, ButtonGroup } from "@chakra-ui/react";
import { AddIcon, CopyIcon } from "@chakra-ui/icons";
import { useContext, useState } from "react";
import EditorContext from "../../context/EditorContext";
import logo from "../../assets/img/logo-white-512px.png";
import NewProjectModal from "../modals/NewProjectModal";
import LoadExampleModal from "../modals/LoadExampleModal";
import { PRIMARY_NAV_HEIGHT } from "../../constants/layoutConstants";

function Navbar() {
  const { file, newProject } = useContext(EditorContext);
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const [examplesOpen, setExamplesOpen] = useState(false);

  return (
    <Box
      flex="0 0 auto"
      height={PRIMARY_NAV_HEIGHT}
      width="100%"
      backgroundColor="black"
      borderBottom="1px solid rgba(255,255,255,0.5)"
      overflow="hidden"
      display="flex"
      alignItems="center"
      flexDir="row"
      paddingLeft="10px"
      paddingRight="20px"
    >
      <Image src={logo} w="50px" h="50px" />
      <Text color="white" fontWeight="bold" fontSize="16px" mr="10px">
        BlockRocket
      </Text>
      <Box flex="1" />
      <ButtonGroup size="sm" isAttached>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          onClick={() => setNewProjectOpen(true)}
        >
          New
        </Button>
        <Button
          leftIcon={<CopyIcon />}
          colorScheme="blue"
          onClick={() => setExamplesOpen(true)}
        >
          Load Example...
        </Button>
      </ButtonGroup>
      <NewProjectModal
        isOpen={newProjectOpen}
        onConfirm={() => {
          newProject();
          setNewProjectOpen(false);
        }}
        onCancel={() => {
          setNewProjectOpen(false);
        }}
      />
      <LoadExampleModal
        isOpen={examplesOpen}
        onClose={() => setExamplesOpen(false)}
      />
    </Box>
  );
}

export default Navbar;
