import { Box, Button } from "@chakra-ui/react";
import { AddIcon, MinusIcon, PlusSquareIcon, DeleteIcon } from "@chakra-ui/icons";
import { useContext } from "react";
import EditorContext, { TOOLS } from "../../context/EditorContext";

function VoxelEditorToolBar() {
  const { activeTool, setActiveTool } = useContext(EditorContext);

  return (
    <Box
      flex="1"
      backgroundColor="white"
      position="absolute"
      borderRadius="5px"
      overflow="hidden"
      shadow="dark-lg"
      top="10px"
      left="10px"
      zIndex="2"
      display="flex"
      flexDir={{
        sm: 'column',
        md: 'row'
      }}
      justifyContent="stretch"
      alignItems="stretch"
    >
      <Button
        onClick={() => setActiveTool(TOOLS.ADD)}
        variant={activeTool === TOOLS.ADD ? "solid" : "ghost"}
        leftIcon={<AddIcon />}
        justifyContent="flex-start"
      >
        Add
      </Button>
      <Button
        onClick={() => setActiveTool(TOOLS.SUBTRACT)}
        variant={activeTool === TOOLS.SUBTRACT ? "solid" : "ghost"}
        leftIcon={<MinusIcon />}
        justifyContent="flex-start"
      >
        Subtract
      </Button>
      <Button
        onClick={() => setActiveTool(TOOLS.RECTANGLE_ADD)}
        variant={activeTool === TOOLS.RECTANGLE_ADD ? "solid" : "ghost"}
        leftIcon={<PlusSquareIcon />}
        justifyContent="flex-start"
      >
        Add Rect
      </Button>
      <Button
        onClick={() => setActiveTool(TOOLS.RECTANGLE_SUBTRACT)}
        variant={activeTool === TOOLS.RECTANGLE_SUBTRACT ? "solid" : "ghost"}
        leftIcon={<DeleteIcon />}
        justifyContent="flex-start"
      >
        Subtract Rect
      </Button>
    </Box>
  );
}

export default VoxelEditorToolBar;
