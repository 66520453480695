import { Box } from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import VoxelRendererSideBar from "./VoxelRendererSideBar";
import VoxelEditorViewer from "./VoxelEditorViewer";

function VoxelRendererView() {
  return (
    <Box
      flex="1"
      position="relative"
      display="flex"
      flexDir="row"
      justifyContent="stretch"
      alignItems="stretch"
    >
      <VoxelEditorViewer isRayTracingEnabled={true} />
      <Sidebar>
        <VoxelRendererSideBar />
      </Sidebar>
    </Box>
  );
}

export default VoxelRendererView;
