import { Box, Button, Flex } from "@chakra-ui/react";
import { saveAs } from "file-saver";

const exportScreenshot = async () => {
  const screenshotBlob = await new Promise((resolve) => {
    document.getElementById("webgl-canvas").childNodes[0].toBlob(resolve);
  });

  saveAs(screenshotBlob, "blockrocket.png");
};

function VoxelRendererSideBar() {
  return (
    <Flex
      borderRadius="lg"
      pos="absolute"
      bg="white"
      height="500px"
      width="200px"
      zIndex="10000000"
      right={0}
      top={"100px"}
      justifyContent="space-between"
      direction="column"
      p="10px"
    >
      <Box></Box>
      <Box>
        <Button onClick={exportScreenshot} w="100%">
          Export
        </Button>
      </Box>
    </Flex>
  );
}

export default VoxelRendererSideBar;
