import { act, Canvas, extend, useThree } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,

  // Backdrop,
  useGLTF,
  RoundedBox,
  Box,
  Plane,
  Sphere,
} from "@react-three/drei";

import {
  DoubleSide,
  BoxGeometry,
  MeshPhysicalMaterial,
  ACESFilmicToneMapping,
  Vector2,
  Mesh,
  Vector3,
} from "three";
import { Raytracer } from "@react-three/lgl";
import { RectAreaLight } from "lgl-tracer";
import { useControls, button } from "leva";
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  PRIMARY_NAV_HEIGHT,
  SECONDARY_NAV_HEIGHT,
  VIEWER_HEIGHT,
} from "../../constants/layoutConstants";
import EditorContext, { TOOLS } from "../../context/EditorContext";
import { getVoxelKeyFromPosition } from "../../utils/voxelUtils";
import { Tooltip } from "@chakra-ui/react";
import range from "lodash.range";

const CAMERA_MOVE_THRESHOLD = 2;

extend({ RectAreaLight });

let canvasRef = createRef();

let pointer, pointerDownPosition;

const GRID_SIZE = 1000,
  GRID_SEGMENTS = 32;

const VOXEL_WIDTH = GRID_SIZE / GRID_SEGMENTS;

const namesToIgnore = ["rollover", "sphere"];

export default function VoxelEditorViewer({ isRayTracingEnabled }) {
  const {
    activeTool,
    data: voxels,
    setData: setVoxels,
    color,
  } = useContext(EditorContext);

  return (
    <Canvas
      gl={{ preserveDrawingBuffer: true }}
      dpr={1.5}
      camera={{ position: [800, 500, 350], far: 10000 }}
      style={{
        height: VIEWER_HEIGHT,
        width: "100vw",
        zIndex: "0",
      }}
      mode="concurrent"
      ref={canvasRef}
      id="webgl-canvas"
    >
      {isRayTracingEnabled ? (
        <Raytracer
          toneMapping={ACESFilmicToneMapping}
          samples={256}
          bounces={6}
          envMapIntensity={0.7}
          enableDenoise={true}
          enableTemporalDenoise={true}
          enableSpatialDenoise={true}
          movingDownsampling={true}
        >
          <Scene
            isRayTracingEnabled={isRayTracingEnabled}
            voxels={voxels}
            setVoxels={setVoxels}
            activeTool={activeTool}
            color={color}
          />
        </Raytracer>
      ) : (
        <Scene
          isRayTracingEnabled={isRayTracingEnabled}
          voxels={voxels}
          setVoxels={setVoxels}
          activeTool={activeTool}
          color={color}
        />
      )}
      <OrbitControls
        makeDefault
        dampingFactor={0.2}
        enabled={activeTool !== TOOLS.RECTANGLE_ADD && activeTool !== TOOLS.RECTANGLE_SUBTRACT}
      />
      <Environment preset="studio" background={false} />
    </Canvas>
  );
}

function Scene({ voxels, setVoxels, activeTool, isRayTracingEnabled, color }) {
  // console.log("voxels", voxels);
  // console.log("color", color);
  const gl = useThree((state) => state.gl);
  const scene = useThree((state) => state.scene);
  const camera = useThree((state) => state.camera);
  const raycaster = useThree((state) => state.raycaster);
  const rollOverMesh = useRef();
  const planeRef = useRef();
  const gridRef = useRef();
  const sphereRef = useRef();

  const getVoxelCoordinateFromIntersect = (intersect) => {
    const position = new Vector3();

    position.copy(intersect.point).add(intersect.face.normal);

    position
      .divideScalar(VOXEL_WIDTH)
      .floor()
      .multiplyScalar(VOXEL_WIDTH)
      .clamp(
        new Vector3(-1000000000, 0, -1000000000),
        new Vector3(1000000000, 1000000000, 100000000)
      )
      .divideScalar(VOXEL_WIDTH)
      .floor();
    return position;
  };

  const addVoxel = useCallback(
    (position) => {
      // console.log("voxel.position", position);

      const newVoxel = {
        x: position.x,
        y: position.y,
        z: position.z,
        color: color,
      };

      const voxelKey = getVoxelKeyFromPosition(
        position.x,
        position.y,
        position.z
      );

      voxels[voxelKey] = newVoxel;
      setVoxels(voxels);
    },
    [voxels, setVoxels, color]
  );

  const subtractVoxel = useCallback(
    (position) => {
      const voxelKey = getVoxelKeyFromPosition(
        position.x,
        position.y,
        position.z
      );

      delete voxels[voxelKey];
      setVoxels(voxels);
    },
    [voxels, setVoxels]
  );

  const getIntersectForPosition = (x, y) => {
    const { width, height } = size;

    pointer.set((x / width) * 2 - 1, -(y / height) * 2 + 1);

    raycaster.setFromCamera(pointer, camera);

    try {
      const intersects = raycaster.intersectObjects(scene.children, false);
      // console.log("intersects", intersects);
      const filteredIntersects = intersects.filter(
        (intersect) => !namesToIgnore.includes(intersect.object.name)
      );

      // console.log("filteredIntersects", filteredIntersects);

      if (filteredIntersects.length > 0) {
        return filteredIntersects[0];
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const size = useThree((state) => state.size);
  const [hoveredVoxelKey, setHoveredVoxelKey] = useState(null);

  useEffect(() => {
    pointer = new Vector2();

    // objects.push(planeRef.current);

    canvasRef.current.addEventListener("pointermove", onPointerMove);
    canvasRef.current.addEventListener("pointerdown", onPointerDown);
    canvasRef.current.addEventListener("pointerup", onPointerUp);

    return () => {
      canvasRef.current.removeEventListener("pointermove", onPointerMove);
      canvasRef.current.removeEventListener("pointerdown", onPointerDown);
      canvasRef.current.removeEventListener("pointerup", onPointerUp);
    };
  });

  function onPointerMove(event) {
    if (isRayTracingEnabled || !scene || !canvasRef.current) {
      return;
    }

    const x = event.offsetX;
    const y = event.offsetY;

    const intersect = getIntersectForPosition(x, y);

    if (intersect) {
      if (intersect.object.name.includes("voxel")) {
        setHoveredVoxelKey(intersect.object.name);
      } else {
        setHoveredVoxelKey(null);
      }

      rollOverMesh.current.material.opacity = 0.5;

      rollOverMesh.current.position
        .copy(intersect.point)
        .add(intersect.face.normal);

      rollOverMesh.current.position
        .divideScalar(VOXEL_WIDTH)
        .floor()
        .multiplyScalar(VOXEL_WIDTH)
        .clamp(
          new Vector3(-1000000000, 0, -1000000000),
          new Vector3(1000000000, 1000000000, 1000000)
        )
        .addScalar(VOXEL_WIDTH / 2);
    } else {
      rollOverMesh.current.material.opacity = 0;
    }
  }

  function onPointerDown(event) {
    pointerDownPosition = { x: event.offsetX, y: event.offsetY };
  }

  function onPointerUp(event) {
    if (isRayTracingEnabled || !scene || !canvasRef.current) {
      return;
    }

    const x = event.offsetX;
    const y = event.offsetY;

    if (
      (activeTool !== TOOLS.RECTANGLE_ADD && activeTool !== TOOLS.RECTANGLE_SUBTRACT) &&
      (Math.abs(pointerDownPosition.x - x) > CAMERA_MOVE_THRESHOLD ||
        Math.abs(pointerDownPosition.y - y) > CAMERA_MOVE_THRESHOLD)
    ) {
      return;
    }

    const intersect = getIntersectForPosition(x, y);

    if (!intersect) {
      return;
    }

    if (activeTool === TOOLS.SUBTRACT) {
      if (intersect.object !== planeRef.current) {
        const voxelKey = intersect.object.name;
        delete voxels[voxelKey];
        setVoxels(voxels);
      }

      // create cube
    } else if (activeTool === TOOLS.RECTANGLE_ADD || activeTool === TOOLS.RECTANGLE_SUBTRACT) {
      const startingIntersect = getIntersectForPosition(
        pointerDownPosition.x,
        pointerDownPosition.y
      );

      const startingCoordinate =
        getVoxelCoordinateFromIntersect(startingIntersect);

      const endingCoordinate = getVoxelCoordinateFromIntersect(intersect);
      console.log("starting ", startingCoordinate);
      console.log("ending ", endingCoordinate);

      // const xRange = range(startingCoordinate.x, endingCoordinate.x, 1);
      // const yRange = range(startingCoordinate.y, endingCoordinate.y, endingCoordinate.y > startingCoordinate.);
      // const zRange = range(startingCoordinate.z, endingCoordinate.z, 1);
      // console.log("xRange", xRange);
      // console.log("yRange", yRange);
      // console.log("zRange", zRange);

      // xRange.map((x) => {
      //   yRange.map((y) => {
      //     zRange.map((z) => {
      //       console.log("add voxel", x, y, z);
      //       addVoxel(new Vector3(x, y, z));
      //     });
      //   });
      // });

      const smallX = Math.min(startingCoordinate.x, endingCoordinate.x);
      const bigX = Math.max(startingCoordinate.x, endingCoordinate.x);

      const smallY = Math.min(startingCoordinate.y, endingCoordinate.y);
      const bigY = Math.max(startingCoordinate.y, endingCoordinate.y);

      const smallZ = Math.min(startingCoordinate.z, endingCoordinate.z);
      const bigZ = Math.max(startingCoordinate.z, endingCoordinate.z);
      for (let x = smallX; x <= bigX; x++) {
        for (let y = smallY; y <= bigY; y++) {
          for (let z = smallZ; z <= bigZ; z++) {
            if (activeTool === TOOLS.RECTANGLE_SUBTRACT) {
              console.log("subtract voxel", x, y, z);
              subtractVoxel(new Vector3(x, y, z))
            } else {
              console.log("add voxel", x, y, z);
              addVoxel(new Vector3(x, y, z));
            }
          }
        }
      }
    } else {
      const position = getVoxelCoordinateFromIntersect(intersect);

      addVoxel(position);
    }
  }

  // useControls({
  //   screenshot: button(() => {
  //     const link = document.createElement("a");
  //     link.setAttribute("download", "canvas.png");
  //     link.setAttribute(
  //       "href",
  //       gl.domElement
  //         .toDataURL("image/png")
  //         .replace("image/png", "image/octet-stream")
  //     );
  //     link.click();
  //   }),
  // });
  return (
    <>
      <mesh ref={sphereRef} name="sphere">
        <sphereBufferGeometry args={[5000]} />
        <meshStandardMaterial
          metalness={0}
          roughness={1}
          color="white"
          side={DoubleSide}
        />
      </mesh>

      <gridHelper
        args={[GRID_SIZE, GRID_SEGMENTS, "#666666", "#666666"]}
        ref={gridRef}
      />

      <Box
        args={[VOXEL_WIDTH, VOXEL_WIDTH, VOXEL_WIDTH]}
        ref={rollOverMesh}
        name="rollover"
        visible={!isRayTracingEnabled && activeTool !== TOOLS.SUBTRACT}
      >
        <meshBasicMaterial color={color} opacity={0.5} transparent={true} />
      </Box>

      <Plane
        args={[1000, 1000]}
        rotation={[-Math.PI / 2, 0, 0]}
        ref={planeRef}
        visible={false}
        name="plane"
      >
        <meshBasicMaterial visible={false} opacity={0} color="blue" />
      </Plane>

      {Object.entries(voxels).map(([voxelKey, voxelData]) => {
        const position = new Vector3(
          voxelData.x * VOXEL_WIDTH,
          voxelData.y * VOXEL_WIDTH,
          voxelData.z * VOXEL_WIDTH
        ).addScalar(VOXEL_WIDTH / 2);

        const isHoveredAndSubtracting =
          activeTool === TOOLS.SUBTRACT && hoveredVoxelKey === voxelKey;

        return (
          <mesh key={voxelKey} name={voxelKey} position={position} opacity={0}>
            <boxBufferGeometry args={[VOXEL_WIDTH, VOXEL_WIDTH, VOXEL_WIDTH]} />
            <meshStandardMaterial
              color={voxelData.color}
              transparent={true}
              opacity={isHoveredAndSubtracting ? 0.5 : 1}
            />
          </mesh>
        );
      })}
      {/* <Backdrop receiveShadow scale={[8, 5, 5]} floor={1.5} position={[0, -0.5, -4]}>
        <meshPhysicalMaterial metalness={0} roughness={0.15} color="#101020" />
      </Backdrop> */}
      <rectAreaLight
        args={["white", 10]}
        width={200}
        height={200}
        position={[300, 300, -200]}
        target={[0, 0, 0]}
      />
    </>
  );
}
