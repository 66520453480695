import { Box, Text } from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import VoxelEditorSideBar from "./VoxelEditorSideBar";
import VoxelEditorToolBar from "./VoxelEditorToolBar";
import VoxelEditorViewer from "./VoxelEditorViewer";
import { SketchPicker, SwatchesPicker } from 'react-color';
import EditorContext from "../../context/EditorContext";
import { useContext } from "react";

function VoxelEditorView() {
  const { color, setColor } = useContext(EditorContext);
  return (
    <Box
      flex="1"
      position="relative"
      display="flex"
      flexDir="row"
      justifyContent="stretch"
      alignItems="stretch"
    >
      <VoxelEditorToolBar />
      <VoxelEditorViewer />
      <Sidebar>
        <VoxelEditorSideBar />
        <Text fontWeight="bold" mb="10px">Voxel Color</Text>
        <SwatchesPicker
        width="250" 
        height="800"
        color={ color }
        onChangeComplete={ (newColor) => setColor(newColor.hex) }
         />
      </Sidebar>
    </Box>
  );
}

export default VoxelEditorView;
